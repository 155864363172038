@import '../mixins';

.icon-anim {
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;

    &__image {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
