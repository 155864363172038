.toggle-wrapper {
  width: 100%;
  max-height: 250px;
  border-top: #999 solid 2px;
  border-bottom: #999 solid 2px;
}

.toggle-wrapper p {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  font-size: 40px;
}

.inside-toggle-wrapper {
  margin-top: -1em;
  margin-bottom: 0.25em;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 30px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 60px;
  position: relative;
  transition: background-color .2s;
  margin: auto;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  transition: 0.2s;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: #71d708;
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.react-switch-toggled {
  border-color: #71d708;
}