@import '../mixins';

.event-list {
    display: none;
    overflow: auto;
    top: 0;
    font-size: 20px;

    @include mobile {
        display: block;
        height: 100%;
    }

    &__container {
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {
            position: relative;
            text-align: left;
            padding: 20px;
            border: 0;
            border-bottom: 1px solid rgba(255, 255, 255, .4);
            border-collapse: collapse;
            height: 70px;

            &__icon {

                &--track {
                    width: 70px;
                    height: 70px;
                    float: left;
                    margin-right: 15px;
                }

                &--arrow {
                    transform: rotate(180deg);
                    opacity: .7;
                    width: 12px;
                    position: absolute;
                    top: calc(50% - 16px);
                    right: 20px;
                }

                .icon-anim {
                    border-radius: 10%;
                }
            }

            &--closed{
                .event-list__container__item__icon,
                span span:not(.state) {
                    opacity: .4;
                }

                .state {
                    color: red;
                    opacity: 1;
                }
            }

            &--closed &__icon--track svg{
                border: 3px solid;
                border-radius: 10%;
                border-collapse: collapse;

                path[fill]:not([fill="currentColor"]) {
                    fill: none !important;
                }
            }

            &--closed &__icon--track .icon-anim {
                border: 3px solid;
                border-collapse: collapse;
            }

            &--sold_out{
                .sold_out__icon--track .icon-anim{
                    border: 3px solid;
                    border-collapse: collapse; 
                    border-radius: 10%;
                    opacity: 0.4;        
                }
                .sold_out__state{
                        color: red;
                        opacity: 1;
                }
                .sold_out__label{
                    opacity: 0.4;
                }
            }

            &__content {
                padding-top: 10px;
                display: block;
                clear: right;
            }

            span {
                display: block;
            }

            a, button {
                color: #fff;
                text-decoration: none;
                display: block;
                cursor: pointer;
            }
			button {
				font-size: 20px;
				text-align: left;
				background-color: transparent;
				border: none;
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;

			}

			button:hover,
			button:focus {
				outline: none;
				text-decoration: none;
			}
        }
    }
}

.slide-pane {
    &__title {
        margin-top: 12px;
    }

    &__header {
        background: transparent;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 80px;
        flex: 0 0 80px;
        border-bottom: 1px solid rgba(255, 255, 255, .4);

        &--detail__icon {
            svg,
            .icon-anim {
                width: 32px;
                margin-bottom: -10px;
                margin-right: 12px;
            }

            .icon-anim {
                border-radius: 10%;
                float: left;
            }
        }

        &--detail__title {
            font-size: 20px;
            line-height: 32px;
            width: 100%;
        }

    }

    &__title-wrapper {
        justify-content: center;
        flex-direction: row;
        margin: auto 60px auto auto;

        &--list {
            justify-content: space-between;
            margin: 0;
            padding: 20px;

            .slide-pane__title--overview {
                margin: 0;
                text-align: right;
                opacity: .4;;
            }
        }
    }

    &__header__icon {
        width: 26px;
        margin: auto;
    }

    &__close {
        margin: 24px 20px 20px;
        padding: 0;
        height: 100%;
    }

    &__title--overview {
        font-size: 12px;
        line-height: 80px;
        flex: 1;
    }

    .picker-container .picker-column .picker-item {
        font-size: 36px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: auto;
        padding: 0;
        max-height: 100%;
        overflow: hidden;

        &--bubble {
            flex: auto;
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
        }

        h1 {
            position: initial;
            margin-bottom: 20px;
            height: 130px;
        }
    }

    footer .button {
        line-height: 100px;
        height: 100px;
    }
}
