@import '../mixins';

.info-message {
    color: #fff;
    font-size: 70px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__text {
        margin: auto 320px;
        text-transform: none;
    }
}

.info-wrapper,
.info-overview {
    height: 100%;
}

.info-overview {

    .info-bubble {
        height: calc(100% - 200px);
    }

    @include mobile {
        display: none;
    }
}

.info-header {

    padding: 50px;

    &__icon {
        
        &--left {
            float: left;
            width: 128px;
        }

        &--right {
            margin: 20px 0;
            float: right;
            width: 96px;
            height: 96px;

            .icon-anim {
                margin-top: -18px;
            }
        }
    }

    &__title {
        margin: 0;
        padding: 0;
        width: auto;
        height: 100px;
        line-height: 100px;
        font-size: 80px;
        display: inline-block;
        clear: both;
        z-index: 2;
    }
}