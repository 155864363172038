@import '../mixins';

.info-bubbles {
    position: relative;
    z-index: 1;
    display: block;

    &, &-wrapper {
        height: 100%;
    }

    &::after {
        content: "";
        clear: both;
        display: table;
    }

    @include mobile {
        flex-wrap: wrap;
    }
}
