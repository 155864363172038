div.picker-container {
    flex: 0 1 auto;
}

div.picker-item {
    padding: 0;
    font-size: 20px;
}

.rmc-picker-mask{
    display:none;
}

.rmc-picker-item-selected{
    color: #fff;
}