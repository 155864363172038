@import '../mixins';

$labelFontSize: 50px;
$stateFontSize: 80px;
$counterFontSize: 170px;

.info-bubble {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
    user-select: none;
    width: 25%;
    height: 100%;
    float: left;
    transition: all .5s;

    &__counter {
        display: block;
        flex-direction: row;
        justify-content: space-around;
        background: transparent;
        line-height: $counterFontSize;
        font-size: $counterFontSize;
    }

    &__label {
        display: block;
        flex-direction: row;
        justify-content: space-around;
        background: transparent;
        font-size: $labelFontSize;
    }

    &__state {
        line-height: $counterFontSize;
        font-size: $stateFontSize;
    }

    &__icon {
        width: 150px;
        height: 150px;
        margin: 100px auto 40px;

        .icon-anim {
            border-radius: 10%;
        }
    }

    &--closed{

        & > * {
            opacity: .4;
        }

        .info-bubble__state {
            opacity: 1;
            color: red;
        }

        .info-bubble__icon svg,
        .info-bubble__icon .icon-anim {
            border: 5px solid;

            path:not([fill="currentColor"]) {
                fill: none !important;
            }
        }
    }
    &--sold_out{
        .info-bubble__icon svg,
        .info-bubble__icon .icon-anim {
            path:not([fill="currentColor"]) {
                fill: none !important;
            }
        }

        .sold_out-bubble__icon{
            opacity: 0.4;
            border: 5px solid;
            border-radius: 10%;
        }

        .sold_out-bubble__label{
            opacity: 0.4;
        }

        .info-bubble__state {
            opacity: 1;
            color: red !important;
        }
    }
}