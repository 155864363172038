//new class to change checksum
.cheksum{
    border-color: #456990;
}

html,
body {
    font-family: 'Roboto Condensed', 'Geneva', 'sans-serif';
    font-size: 50px;
    text-align: center;
    color: #ffffff;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    font-weight: bold;
    text-transform: uppercase;
}

.gradient {
    background-image:
        linear-gradient(180deg, #213857 40%, transparent),
        linear-gradient(327deg, #456990, #213857) !important;
}

input, textarea, select, option, button {
    font-family: 'Roboto Condensed', 'Geneva', 'sans-serif';
}

.app {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@keyframes ellipsis {
    to {
        width: 10em;    
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 10em;    
    }
}

.message {
    width: auto;
    height: auto;
    margin: auto;
    padding: 0;
    font-size: 100px;

    &--load {
        .one {
            opacity: 0;
            -webkit-animation: dot 1.3s infinite;
            -webkit-animation-delay: 0.0s;
            animation: dot 1.3s infinite;
            animation-delay: 0.0s;
        }

        .two {
            opacity: 0;
            -webkit-animation: dot 1.3s infinite;
            -webkit-animation-delay: 0.2s;
            animation: dot 1.3s infinite;
            animation-delay: 0.2s;
        }

        .three {
            opacity: 0;
            -webkit-animation: dot 1.3s infinite;
            -webkit-animation-delay: 0.3s;
            animation: dot 1.3s infinite;
            animation-delay: 0.3s;
        }
    }
}

@-webkit-keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}