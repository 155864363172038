@import '../mixins';

footer {

    .button-wrapper {
        background: #71d708;
        position: relative;
        display: block;
        clear: both;
        bottom: 0;
        transition: max-height 1s;
        overflow: hidden;

        &.visible {
            max-height: 50px;

            @include mobile {
                max-height: 100px;
            }
        }

        &.hidden {
            max-height: 0;
        }

        &:focus {
            border: 0 !important;
        }
    }
}