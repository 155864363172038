@import '../mixins';

.special-message-input {
	position: relative;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, .4);
    margin: auto 10%;
    max-height: 5em;
    overflow: hidden;
    flex: 1;
    height: 3em;
    font-size: 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: none;

	&__placeholder {
		opacity: .4;
		position: absolute;
		width: 100%;
		text-align: center;
		z-index: 0;
	}

	&__content {
		border: 0;
		outline: none;
		position: relative;
		z-index: 1;
		min-height: 1em;
		width: 100%;
		margin: auto;
	}
}