@import '../mixins';

.editor-wrapper {
    height: 100%;

    .react-tabs,
    .editor-view {
        @include mobile {
            display: none !important;
        }
    }

    .react-tabs,
    .editor-view {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;

        .picker-container {
            width: 100px;
            margin: auto;
        }

        .info-bubble__icon {
            width: 50px;
            height: 50px;
            margin: 10px auto auto;
        }

        .info-bubble__label {
            margin-top: 100px;
        }

        .editor-header {
            width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, .4);
            justify-content: space-between;

            &__title-wrapper {
                margin: auto 30px;
            }
            
            &__icon--left {
                float: left;
                width: 26px;
                margin-right: 12px;
            }

            &__title {
                float: left;
                opacity: .4;
                font-size: 12px;
            }
        }

        .react-tabs__tab-list {
            margin: 0;
            border-bottom: 0;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .react-tabs__tab-list,
        .editor-header {
            height: 80px;
            min-height: 80px;
            display: flex;
            flex-direction: row;
            font-size: 20px;
            position: relative;
            top: 0;

            .react-tabs__tab {
                position: Relative;
                bottom: 0;
                opacity: .4;
                right: 60px;

                &--selected {
                    background-color: transparent;
                    color: #fff !important;
                    opacity: 1;
                    border-bottom: 5px solid #213857;
                    margin-bottom: -3px;
                }
            }
        }

        .react-tabs__tab-panel,
        .editor-content {
            max-height: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;

            &--selected {
                flex: auto;
                display: flex;
                flex-direction: column;
            }
        }

        .editor-content {
            flex: 1;
        } 
    }

    .info-bubble {
        flex-direction: column-reverse;

        &__label {
            font-size: 12px;
        }

        &--sold_out,
        &--closed {
            .icon-anim {
                border-width: 2px;
            }
        }
    }
}
