@import '../mixins';

.button {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 50px;
    height: 50px;
    border: 0;
    margin: 0;
    flex: 0 1 20%;
    font-size: 20px;
    display: block;
    transition: all 1s;

    &:focus {
        border: 0 !important;
    }

    &__label,
    &__icon {
        display: inline-block;
    }

    &__icon {
        margin-left: 10px;
        width: 14px;
        opacity: .7;
    }

    &--cancel {
        background: #647489;
    }

    &--disable {
        background: #d83a2f;
    }

    &--cancel,
    &--disable {
        float: left;
        min-width: 20%;

        @include mobile {
            min-width: 50%;
        }

        &.visible {
            margin-left: 0;
        }

        &.hidden {
            margin-left: -100%;
        }
    }

    &--save {
        background: #71d708;
        float: right;
        min-width: 80%;

        @include mobile {
            min-width: 50%;
        }

    }

    &--saved {
        color: #5e6474;
        width: 100%;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        overflow: hidden;

        &.visible {
            max-height: 50px;
            opacity: 1;

            @include mobile {
                max-height: 100px;
            }
        }

        &.hidden {
            max-height: 0;
            opacity: 0;
        }
    }

    &--error {
        background-color: #990000;
        color: white;
        width: 100%;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        overflow: hidden;

        &.visible {
            max-height: 50px;
            opacity: 1;

            @include mobile {
                max-height: 100px;
            }
        }

        &.hidden {
            max-height: 0;
            opacity: 0;
        }
    }

    &--save {
        &.visible {
            margin-right: 0;
        }

        &.hidden {
            margin-right: -100%;
        }
    }

    &.full-width {
        min-width: 100%;
    }

    &.mobile-full-width {
        @include mobile {
            min-width: 100%;
        }
    }

    &.mobile-hidden {
        @include mobile {
            margin-left: -100%;
        }
    }
}
