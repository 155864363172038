$tablet-width: 768px;
$desktop-width: 1024px;

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: #{$tablet-width}) {
		@content;
	}
}

/* roboto-condensed-regular - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	font-stretch: condensed;
	line-height: 1;
	letter-spacing: normal;
	src: url('/fonts/roboto-condensed-v17-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
		url('/fonts/roboto-condensed-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/fonts/roboto-condensed-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('/fonts/roboto-condensed-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('/fonts/roboto-condensed-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/fonts/roboto-condensed-v17-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
